import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import SiteMetadata from "../components/SiteMetadata"
import Newsletter from "../components/Newsletter"
import Recipe from "../components/Recipe"
import Review from "../components/Review"
import styled from "styled-components"
import Layout from "../layouts/Layout"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import React from "react"
import { BLOCKS, MARKS, INLINES } from "@contentful/rich-text-types"

const ArticleWrapper = styled.div`
  h1 {
    font-size: 1.5rem;
    color: #2c5282;
    font-weight: 600;
  }
  img {
    width: 65ch;
    max-width: 100%;
  }
  video {
    width: 65ch;
    max-width: 100%;
  }
  p {
    font-size: 1.25rem;
    line-height: 1.6;
    margin-bottom: 1rem;
    max-width: 65ch;
  }
  a {
    color: #2b6cb0;
  }
  a:hover {
    cursor: pointer;
    color: #3182ce;
    text-decoration: underline;
  }
`

export default props => {
  const {
    description,
    gallery,
    name,
    content,
    author,
    summary,
    thumbnail,
  } = props.data.item

  console.log(content)
 let embeddedRecipes = content.json.content.filter(function (el) {
   return el.nodeType === "embedded-entry-block"
 }).filter( function (el) {
   return el.data.target.sys.contentType.sys.id === "recipe"
 })

  const Bold = ({ children }) => <p className="font-bold">{children}</p>
  const Header2 = ({ children }) => <p className="font-bold text-2xl text-blue-600 leading-7 mt-6">{children}</p>
  const Header3 = ({ children }) => <p className="font-bold text-xl text-gray-900 leading-5 mt-2 mb-4">{children}</p>
  const Header4 = ({ children }) => <p className="text-lg font-bold mt-6 mb-10">{children}</p>
  const Text = ({ children }) => <p className="align-center">{children}</p>
  const Ul = ({ children }) => <ul className="list-none"> {children} </ul>

  const options = {
    renderMark: {
      [MARKS.BOLD]: text => <Bold>{text}</Bold>,
    },
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
      [BLOCKS.UL_LIST]: (node, children) => <Ul>{children}</Ul>,
      [BLOCKS.HEADING_2]: (node, children) => <Header2>{children}</Header2>,
      [BLOCKS.HEADING_3]: (node, children) => <Header3>{children}</Header3>,
      [BLOCKS.HEADING_4]: (node, children) => <Header4>{children}</Header4>,
      [INLINES.HYPERLINK]: (node, children) => <a href={node.data.uri}>{children}</a>,
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {console.log(node.data.target.fields.slug['en-US']); return(<a href={"/" + node.data.target.fields.slug['en-US']}>{children}</a>)},
      [BLOCKS.EMBEDDED_ASSET]: node => {
        const image = node.data?.target?.fields?.file?.["en-US"]
        const title = node.data?.target?.fields?.title?.["en-US"]
        const width =
          node.data?.target?.fields?.file?.["en-US"]?.details?.image?.width
        let query = width ? "w=630&q=80" : false
        return query ? (
          <img src={`${image.url}?${query}`} alt={title} />
        ) : (
          <video width="450" autoPlay muted loop>
            <source src={`${image.url}?${query}`} type="video/mp4"></source>
          </video>
        )
      },
      [BLOCKS.EMBEDDED_ENTRY]: node => {
        if (node.data.target.sys.contentType.sys.id === "review") {
          const review = node.data.target.fields
          return <Review review={review} />
        } else {
          const recipe = node.data.target.fields
          return <Recipe recipe={recipe} />
        }
      },
    },
  }

  return (
    <Layout>
      <SiteMetadata
        title={name}
        description={summary}
        image={thumbnail.localFile.publicURL}
        recipe={embeddedRecipes.length === 1 ? embeddedRecipes[0].data.target.fields : false}
      />

      <div className="container mt-12">
        <div className="mb-12 grid grid-cols-1 sm:grid-cols-8 sm:gap-x-24">
          <div className="pt-40 sm:pt-0 min-h-1/4 relative z-10 col-start-1 sm:col-start-6 sm:col-span-3 row-start-1 px-4 pb-3 bg-gradient-to-t from-black sm:bg-none">
            <p className="text-sm font-medium text-white sm:mb-1 sm:text-gray-600">
              {author?.name}
            </p>
            <h1 className="text-xl font-bold text-white sm:text-2xl sm:leading-7 sm:text-blue-800 md:text-3xl">
              {name}
            </h1>
          </div>
          <div className="mt-8 lg:mt-0 col-start-1 sm:col-start-6 sm:col-span-3 row-start-2 px-4 sm:pb-16">
            <h2 className="text-lg leading-6 font-medium text-gray-900 flex items-center sm:mt-2 sm:mb-4">
              {summary}
            </h2>
            <p className="flex items-center text-md font-medium my-5 sm:mt-2 sm:mb-4">
              {description?.description}
            </p>
          </div>
          <div className="col-start-1 row-start-1 flex sm:col-span-5 sm:row-span-3">
            <div className="w-full grid grid-cols-3 grid-rows-2 gap-2">
              <div className="relative col-span-3 row-span-2 md:col-span-3">
                {gallery && gallery.length === 1 && (
                  <Img
                    className="absolute inset-0 w-full h-full object-cover bg-gray-100 sm:rounded-lg"
                    fixed={gallery[0].localFile.childImageSharp.fluid}
                    alt={name}
                  />
                )}
              </div>
              <div className="relative hidden">
                {gallery && gallery.length === 1 && (
                  <Img
                    className="absolute inset-0 w-full h-full object-cover bg-gray-100 sm:rounded-lg"
                    fixed={gallery[0].localFile.childImageSharp.fluid}
                    alt={name}
                  />
                )}
              </div>
              <div className="relative hidden">
                {gallery && gallery.length === 1 && (
                  <Img
                    className="absolute inset-0 w-full h-full object-cover bg-gray-100 sm:rounded-lg"
                    fixed={gallery[0].localFile.childImageSharp.fluid}
                    alt={name}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
        <ArticleWrapper>
          {content?.json && documentToReactComponents(content.json, options)}
        </ArticleWrapper>

      </div>
      <Newsletter />
    </Layout>
  )
}

export const query = graphql`
  query PostItemQUery($slug: String!) {
    item: contentfulPost(slug: { eq: $slug }) {
      description {
        description
      }
      content {
        json
      }
      author{
        name
      }
      gallery {
        id
        localFile {
          childImageSharp {
            fluid(maxWidth: 960, quality: 85) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          childImageSharp {
            fixed(width: 960, quality: 85) {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        title
      }
      name
      recipe {
        ...RecipeCard
      }
      related {
        ...PostCard
      }
      summary
      thumbnail {
        localFile {
          publicURL
        }
      }
    }
  }
`
