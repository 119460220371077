import React from "react"
import styled from "styled-components"

const Recipe = props => {
  const { recipe } = props

const StepHeader = styled.h4`
  overflow: hidden;
  position: relative;

  :after {
    display: inline-block;
    margin-left: 16px;
    content: "";
    width: 100%;
    border-top: 1px solid #eaecf0;
    position: absolute;
    margin-top: 12px;
  }
`

  return (
    <div className="grid grid-cols-1 lg:grid-cols-8 gap-24 mt-12">
        <div className="col-span-1 lg:col-span-5 flex flex-wrap">
            <h3 className="text-xl leading-tight font-semibold tracking-tight text-blue-800 sm:text-2xl">
              {recipe?.name?.['en-US']}
            </h3>
            {recipe?.steps['en-US'] && (
            <div className="my-4 text-base text-gray-700 whitespace-pre-line">
                <h3 className="text-xl leading-tight font-semibold tracking-tight text-blue-600 sm:text-2xl">
                Instructions
                </h3>
                {recipe.steps['en-US'].map((item, i) => (
                <div key={i} className="mb-4">
                    <StepHeader className="step-header text-lg leading-6 font-medium text-gray-900 mt-12 mb-4">
                    Step {i + 1}
                    </StepHeader>
                    <p>{item}</p>
                </div>
                ))}
            </div>
            )}
        </div>
        <div className="bg-gray-200 p-12 col-span-1 lg:col-span-3 flex flex-col content-start rounded-lg ">
            {recipe?.ingredients['en-US'] && (
            <div className="flex-shrink my-4 text-base text-gray-700 whitespace-pre-line">
                <h3 className="text-xl leading-tight font-semibold tracking-tight text-blue-600 sm:text-2xl">
                Ingredients
                </h3>
                <ul>
                {recipe.ingredients['en-US'].map((item, i) => (
                    <li className="py-2" key={i}>
                    {item}
                    </li>
                ))}
                </ul>
            </div>
            )}
            {recipe?.tools['en-US'] && (
            <div className="flex-auto my-4 text-base text-gray-700 whitespace-pre-line">
                <h3 className="text-xl leading-tight font-semibold tracking-tight text-blue-600 sm:text-2xl">
                Tools
                </h3>
                <ul>
                {recipe.tools['en-US'].map((item, i) => (
                    <li className="py-2" key={i}>
                    {item}
                    </li>
                ))}
                </ul>
            </div>
            )}
        </div>
    </div>
  )
}

export default Recipe