import React from "react"

const Review = props => {
  const { review } = props
  return (
    <div className="container mt-12 ">
      <div
        style={{ minHeight: "30rem" }}
        className="max-w-3xl mb-12 grid grid-cols-1 sm:grid-cols-8 sm:gap-x-24"
      >
        <div className="relative z-10 col-start-1 sm:col-start-5 sm:col-span-4 row-start-1 px-4 pb-3 bg-gradient-to-t from-black sm:bg-none">
          <a
            className="absolute bottom-0 sm:relative"
            href={review?.link?.["en-US"]}
          >
            <h4 className="text-xl font-bold text-white sm:text-2xl sm:leading-7 sm:text-blue-800 md:text-3xl">
              {review?.name?.["en-US"]}
            </h4>
            <p className="flex text-md font-medium my-0 text-white sm:text-blue-600 sm:my-5 sm:mt-0 sm:mb-4">
              {review?.price?.["en-US"]}
            </p>
          </a>
          {review?.features?.["en-US"] && (
            <>
              <h5 className="hidden md:block text-xl font-bold text-white sm:text-2xl sm:leading-7 sm:text-blue-600 md:text-2xl">
                Features
              </h5>
              <ul className="hidden md:block">
                {review?.features?.["en-US"].map((item, index) => (
                  <li key={index}>- {item}</li>
                ))}
              </ul>
            </>
          )}
          {review?.pros?.["en-US"] && (
            <>
              <h5 className="hidden md:block text-xl font-bold text-white sm:text-2xl sm:leading-7 sm:text-blue-600 md:text-2xl">
                Pros
              </h5>
              <ul className="hidden md:block">
                {review?.pros?.["en-US"].map((item, index) => (
                  <li key={index}>- {item}</li>
                ))}
              </ul>
            </>
          )}
          {review?.cons?.["en-US"] && (
            <>
              <h5 className="hidden md:block mt-4 text-xl font-bold text-white sm:text-2xl sm:leading-7 sm:text-blue-600 md:text-2xl">
                Cons
              </h5>
              <ul className="hidden md:block">
                {review?.cons?.["en-US"].map((item, index) => (
                  <li key={index}>- {item}</li>
                ))}
              </ul>
            </>
          )}
        </div>
        <div className="col-start-1 row-start-1 flex sm:col-span-4 sm:row-span-3">
          <div className="w-full grid grid-cols-3 grid-rows-2 gap-2">
            <div className="relative col-span-3 row-span-2 md:col-span-3">
              {review?.images?.["en-US"] && (
                <img
                  className="inset-0 h-full object-cover bg-gray-100 sm:rounded-lg"
                  src={`https:${review?.images?.["en-US"][0]?.fields?.file?.["en-US"]?.url}`}
                  alt={`${review?.images?.["en-US"][0]?.fields?.title?.["en-US"]}`}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Review
